<template>
  <b-card no-body class="mb-1">
    <b-card-header
      :class="!visible ? 'p-1' : 'px-1 pt-1 pb-0'"
      class="cursor-pointer"
      @click="visible = !visible"
      style="transition: all 0.5s ease"
    >
      <div class="d-flex justify-content-between w-100">
        <h5>{{title}}</h5>
        <feather-icon :icon="visible ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body class="pt-0">
        <div class="d-flex justify-content-start" style="overflow: auto">
          <!-- headers -->
          <b-card
            class="border-right sticky-card"
            style="flex-shrink: 0; width: 14rem"
            bg-variant="primary"
          >
            <b-list-group class="list-group list-group-flush text-white">
              <b-list-group-item
                v-for="(header, index) of headers"
                :key="index"
                class="list-group-item px-0 bg-primary text-white cursor-pointer"
                @click="setHover(header)"
              >
                <h6 class="align-self-center text-white my-auto">
                  {{ header.name }}
                </h6>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <!-- data -->
          <b-card
            v-for="(clinic, indexData) of dataStats"
            :key="indexData"
            body-class="px-0"
            style="flex-shrink: 0"
            class="rounded border-right"
          >
            <b-list-group class="list-group list-group-flush">
              <!-- Object.keys(clinic) -->
              <b-list-group-item
                v-for="(info, indexHeader) in headers"
                :key="indexHeader"
                class="list-group-item list-group-item-action px-0 disabled px-2"
                :class="info.isClick ? 'bg-light' : ''"
              >
                <h6 class="align-self-center my-auto">
                  {{ info.money ? "$" : "" }} {{ clinic[info.key] }}
                </h6>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BTable,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
export default {
  components: {
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BTable,
    BListGroup,
    BListGroupItem,
    StatisticCardHorizontal,
  },
  props: {
    facilityData: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: "STATISTICS"
    },
    sorter: {
      type: String,
      required: false,
      default: "semaglutide_name"
    },
  },
  data() {
    return {
      visible: true,
      fields: ["stats"],
      items: [],
      dataStats: [...this.facilityData].sort((a, b) => this.sortingArray(a,b)),
    };
  },
  watch: {
    facilityData() {
      const sortedArray = [...this.facilityData].sort((a, b) => this.sortingArray(a,b));
      this.dataStats = sortedArray;
    },
  },
  methods: {
    sortingArray(a, b) {
      return a[this.sorter].localeCompare(b[this.sorter])
    },
    currencyPrefix(indexOrBoolean) {
      if (typeof indexOrBoolean === "boolean") {
        return indexOrBoolean ? "$" : "";
      }

      if (
        indexOrBoolean === 5 ||
        indexOrBoolean === 6 ||
        indexOrBoolean === 7
      ) {
        return "$";
      }

      return "";
    },
    setHover(item) {
      this.headers.forEach((header) => {
        if (header.key === item.key) {
          header.isClick = true;
        } else {
          header.isClick = false;
        }
      });
    },
  },
};
</script>
