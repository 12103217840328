<template>
  <div>
    <BonusFilter @searchFilter="searchFilter" @viewTable="viewTable" :filters="filters" :loading="loading" />
    <b-card v-if="globalData.semaglutide_applied">
      <b-card-header>
        <div>
          <h5>GLOBAL STATISTICS</h5>
        </div>
      </b-card-header>
      <div class="default-layout">
        <div>
          <p>
            Aplied
          </p>
          {{ formatNumber(globalData.semaglutide_applied) }}
        </div>
        <div>
          <p>
            Quantity
          </p>
          {{ formatNumber(globalData.semaglutide_quantity) }}
        </div>
        <div>
          <p>
            Total
          </p>
          {{ formatMoney(globalData.semaglutide_total) }}
        </div>
      </div>
    </b-card>
    <BonusStatistic v-if="facilityData.length" title="FACILITIES STATISTICS" sorter="facility_name"
      :facilityData="facilityData" :headers="headerFacility" />
    <BonusStatistic v-if="detailData.length" title="DETAILED STATISTICS" sorter="semaglutide_name"
      :facilityData="detailData" :headers="headerDetail" />
    <b-card v-if="items.length">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ $t("Show") }}</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
            class="per-page-selector d-inline-block mx-50" />
          <label>{{ $t("Entries") }}</label>
        </b-col>
      </b-row>
      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(patient_name)="data">
          <b-link @click="openRecipeInNewTab({ id: data.item.patient_id }, 'patients-view')">{{ data.item.patient_name
            }}</b-link>
        </template>
        <template #cell(visit_date)="data">
          <span>{{ new Date(data.item.visit_date).toLocaleDateString("en-US") }}</span>
        </template>
        <template #cell(birth_date)="data">
          <span>{{ new Date(data.item.birth_date).toLocaleDateString("en-US") }}</span>
        </template>
        <template #cell(type_visit)="data">
          <span>{{ data.item.type_visit.split("(")[0] }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button v-if="$can('nurse_procedure', 'module_patient.office_visit')"
              @click="openRecipeInNewTab({ recipeId: data.item.id }, 'recipe-patient')" variant="flat-primary"
              class="btn-icon btn-sm" v-b-tooltip.hover.top="`See visit`">
              <feather-icon icon="EyeIcon" size="16" />
            </b-button>
          </div>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <b-card v-if="!items.length &&
      !detailData.length &&
      !globalData.semaglutide_applied &&
      !facilityData.length &&
      loading == false" class="not-found">
      <p>Not found data!</p>
    </b-card>
    <b-card v-else-if="loading == true">
      <SpinnerLoading />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import { BCard, BTable, BCardHeader, BRow, BCol, BFormCheckbox, BLink, BButton, VBTooltip, } from "bootstrap-vue"
import ToastNotification from "@/components/ToastNotification";
import vSelect from "vue-select";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";

import RoleCollapse from "./components/RoleCollapse";
import BonusStatistic from "./SRStatistic";
import BonusFilter from "./SRFilter";

import { formatPhone } from "@/mixins/formatPhone.js";
import axiosBonusData from "@/services/admin/admin-data/bonus-data";
import { isNumber } from "@vueuse/core";

export default {
  components: {
    ToastNotification,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    BTable,
    vSelect,
    PaginationTable,
    RoleCollapse,
    BonusFilter,
    BonusStatistic,
    BFormCheckbox,
    SpinnerLoading,
    BLink,
    BButton
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    const dateUsa = new Date().toLocaleDateString("en-US");
    const date = dateUsa.split("/").join("-");
    return {
      formatPhone,
      dataFilter: null,
      filters: {
        date_start: `${date}`,
        date_end: `${date}`,
        user_id: null,
        facility_id: null,
      },
      rolesData: [],
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1
      },
      perPage: 25,
      perPageOptions: [10, 25, 50, 100],
      facilityData: [],
      globalData: {},
      detailData: [],
      items: [],
      viewTableBonus: "",
      loading: false,
      fields: [
        // { key: "show_details", label: "Show" },
        { key: "visit_date", label: "Date" },
        { key: "facility_name", label: "Facility", class: "th-class-adjusted-admindata" },
        { key: "semaglutide_name", label: "SMG", class: "th-class-adjusted-admindata" },
        { key: "semaglutide_quantity", label: "SMG Receted", class: "th-class-adjusted-admindata" },
        { key: "semaglutide_total", label: "SMG Total", class: "th-class-adjusted-admindata" },
        { key: "appointment_total", label: "Total Visiti", class: "th-class-adjusted-admindata" },
        { key: "appointment_ajustment", label: "Ajustment", class: "th-class-adjusted-admindata" },
        { key: "type_visit", label: "Type visit", class: "th-class-adjusted-admindata" },
        { key: "semaglutide_applied", label: "SMG Applied", label: "Aplied Dose", class: "th-class-adjusted-admindata" },
        { key: "patient_name", label: "patient", class: "th-class-adjusted-admindata" },
        { key: "birth_date", label: "DOB", class: "th-class-adjusted-admindata" },
        { key: "frontdesk_name", label: "frontdesk", class: "th-class-adjusted-admindata" },
        { key: "oma_name", label: "oma", class: "th-class-adjusted-admindata" },
        { key: "nurse_name", label: "nurse", class: "th-class-adjusted-admindata" },
        "actions"
      ],
      headerDetail: [
        {
          name: "Name",
          key: "semaglutide_name",
          money: false,
          isClick: false,
        },
        {
          name: "Total",
          key: "semaglutide_total",
          money: true,
          isClick: false,
        },
        {
          name: "Indicated",
          key: "semaglutide_quantity",
          money: false,
          isClick: false,
        },
        {
          name: "Aplied",
          key: "semaglutide_applied",
          money: false,
          isClick: false,
        },
      ],
      headerFacility: [
        {
          name: "Facility",
          key: "facility_name",
          money: false,
          isClick: false,
        },
        {
          name: "Total",
          key: "semaglutide_total",
          money: true,
          isClick: false,
        },
        {
          name: "Indicated",
          key: "semaglutide_quantity",
          money: false,
          isClick: false,
        },
        {
          name: "Applied",
          key: "semaglutide_applied",
          money: false,
          isClick: false,
        },
      ],
    };
  },
  methods: {
    openRecipeInNewTab(params, name) {
      const url = this.$router.resolve({
        name,
        params,
      }).href;
      window.open(url, '_blank');
    },
    formatNumber(number) {
      if (isNaN(number)) return
      return new Intl.NumberFormat('en-US').format(
        number,
      )
    },
    formatMoney(number) {
      if (isNaN(number)) return
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(
        number,
      )
    },
    pageChanged(page) {
      this.loading = true;
      axiosBonusData
        .semaglutideFilter(this.dataFilter, this.perPage, page)
        .then(({ registro: { detail: { data, total, current_page, per_page, ...res }, statistics: { detail_statistics, facility_statistics, global_statistics } } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.perPage = per_page;
          this.currentPage.page = current_page;

          this.loading = false;
          this.facilityData = facility_statistics;
          this.detailData = detail_statistics;
          this.globalData = global_statistics;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    searchFilter(value) {
      this.loading = true;
      this.dataFilter = value;
      axiosBonusData
        .semaglutideFilter(value, this.perPage, 1)
        .then(({ registro: { detail: { data, total, current_page, per_page, ...res }, statistics: { detail_statistics, facility_statistics, global_statistics } } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.perPage = per_page;
          this.currentPage.page = current_page;

          this.facilityData = facility_statistics;
          this.detailData = detail_statistics;
          this.globalData = global_statistics;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    downloadDocument(event) {
      axiosAD.adminDataExport(this.dataFilter).then((res) => {
        const blob = new Blob([res]);
        const filename = `admin_data_${this.dataFilter.date_start}_to_${this.dataFilter.date_end}.xlsx`;

        const urlExcel = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlExcel;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(urlExcel);
        document.body.removeChild(a);
      });
    },
    parseArray(array) {
      if (!Array.isArray(array)) {
        return [array];
      }
      return array;
    },
    viewTable(event) {
      this.viewTableBonus = event;
    },
  },
};
</script>
<style lang="scss">
.th-class-adjusted-admindata {
  padding: 0.5rem 0.5rem !important;
}

.default-layout {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: center;
  font-size: 3vh
}

.not-found {
  justify-content: center;
  text-align: center;
  font-size: 3vh
}
</style>
