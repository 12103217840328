<template>
  <b-card class="border shadow-lg rounded">
    <b-img src="@/assets/images/avatars/2.png" alt="Card image" fluid></b-img>
    <b-card-text>
      <div class="d-flex justify-content-between mt-2">
        <small class="text-muted">Total Bonus</small>
        <b-badge variant="primary">
          <small>{{ summary.total_bonus }}</small>
        </b-badge>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-2">
        <h5>{{ name }}</h5>
        <feather-icon icon="ArrowDownRightIcon"></feather-icon>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BCardText, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      default: "@/assets/images/avatars/2.png", // Ruta de la imagen por defecto
    },
    summary: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
/* Agrega estilos personalizados si es necesario */
</style>
